@import './productsans.styl'

body
  overflow hidden
  margin 0
  padding 0
  color #fff !important
  font-weight 300
  font-size 107%
  font-family 'Product Sans', Helvetica, Arial, sans-serif
  animation background-pan 7s linear infinite
  background linear-gradient(
        to right,         
        #000 0%,
        #8226ED 50%,
        #000 60%,
        #000 100%
  )

  background-size 200%
  height 100%

  &.toggled
    animation background-pan 10s linear infinite

    .avatarContainer, h1
      filter drop-shadow(10px 10px 20px #000)
    
    ul
      li
        img
          filter drop-shadow(10px 10px 20px #000)


    & > #title
      opacity 0

    & > #tiles
      & > .tile
        &:hover
          opacity 0.1 !important

@keyframes background-pan
  from
    background-position 0% center

  to
    background-position -200% center

.main
  position relative
  text-align center
  top 25%
  z-index 3
  pointer-events none

.avatarContainer
  width max-content
  height max-content
  margin 0 auto
  border-radius 100px
  transition filter 0.5s ease-in-out

.avatar
  border-radius 100%
  pointer-events all

.topping
  & > .container-top
    min-width 50%
    max-width 100%

.summary
  min-height 100vh

  & > h1
    font-weight 500
    animation gradient 3s ease infinite
    background-size 200% 100%
    animation-direction alternate
    width max-content
    border-radius 100px
    margin 30px auto
    transition filter 0.5s ease-in-out

.list-group
  text-align center

.tiles
  height calc(100% - 1px)
  width calc(100% - 1px)
  position absolute
  z-index 2
  display grid
  grid-template-columns repeat(var(--columns), 1fr)
  grid-template-rows repeat(var(--rows), 1fr)

.tile
  position relative

  &:hover
    &:before
      background-color #141414

  &:before
    background-color #000
    content ''
    inset 0.5px
    position absolute
    transition background-color .1s linear

ul
  padding 10px
  margin 0 auto
  z-index 5
  position relative

  li
    display inline-block
    list-style-type none
    position relative
    pointer-events all

    img
      width 12vh
      transition all 0.2s ease-in-out
      transition filter 0.5s ease-in-out
      position relative

      &:hover
        transform scale(1.075)

@media only screen and (max-width 700px)
  h1
    font-size 350%

  ul
    width 30vh

  li
    padding 5px

  .avatar
    width 10vh
    height 10vh

@media only screen and (min-width 700px)
  h1
    font-size 650%

  ul
    width max-content

  li
    padding-left 30px

    &:first-of-type
      padding-left 0

  .avatar
    width 16vh
    height 16vh
