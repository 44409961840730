@font-face {
  font-family: Product Sans;
  font-style: normal;
  font-weight: 500;
  src: local(Product Sans Medium), local(ProductSans-Medium), url("https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShd5PSbS2lBkm8.woff2") format("woff2");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Product Sans;
  font-style: normal;
  font-weight: 500;
  src: local(Product Sans Medium), local(ProductSans-Medium), url("https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShd5PSbTGlBkm8.woff2") format("woff2");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Product Sans;
  font-style: normal;
  font-weight: 500;
  src: local(Product Sans Medium), local(ProductSans-Medium), url("https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShd5PSbQWlBkm8.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Product Sans;
  font-style: normal;
  font-weight: 500;
  src: local(Product Sans Medium), local(ProductSans-Medium), url("https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShd5PSbT2lB.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  background: linear-gradient(to right, #000 0%, #8226ed 50%, #000 60% 100%) 0 0 / 200%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Product Sans, Helvetica, Arial, sans-serif;
  font-size: 107%;
  font-weight: 300;
  animation: 7s linear infinite background-pan;
  overflow: hidden;
  color: #fff !important;
}

body.toggled {
  animation: 10s linear infinite background-pan;
}

body.toggled .avatarContainer, body.toggled h1, body.toggled ul li img {
  filter: drop-shadow(10px 10px 20px #000);
}

body.toggled > #title {
  opacity: 0;
}

body.toggled > #tiles > .tile:hover {
  opacity: .1 !important;
}

.main {
  text-align: center;
  z-index: 3;
  pointer-events: none;
  position: relative;
  top: 25%;
}

.avatarContainer {
  border-radius: 100px;
  width: max-content;
  height: max-content;
  margin: 0 auto;
  transition: filter .5s ease-in-out;
}

.avatar {
  pointer-events: all;
  border-radius: 100%;
}

.topping > .container-top {
  min-width: 50%;
  max-width: 100%;
}

.summary {
  min-height: 100vh;
}

.summary > h1 {
  background-size: 200% 100%;
  border-radius: 100px;
  width: max-content;
  margin: 30px auto;
  font-weight: 500;
  transition: filter .5s ease-in-out;
  animation: 3s infinite alternate gradient;
}

.list-group {
  text-align: center;
}

.tiles {
  z-index: 2;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: repeat(var(--rows), 1fr);
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  display: grid;
  position: absolute;
}

.tile {
  position: relative;
}

.tile:hover:before {
  background-color: #141414;
}

.tile:before {
  content: "";
  background-color: #000;
  transition: background-color .1s linear;
  position: absolute;
  inset: .5px;
}

ul {
  z-index: 5;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

ul li {
  pointer-events: all;
  list-style-type: none;
  display: inline-block;
  position: relative;
}

ul li img {
  width: 12vh;
  transition: filter .5s ease-in-out;
  position: relative;
}

ul li img:hover {
  transform: scale(1.075);
}

@media only screen and (width <= 700px) {
  h1 {
    font-size: 350%;
  }

  ul {
    width: 30vh;
  }

  li {
    padding: 5px;
  }

  .avatar {
    width: 10vh;
    height: 10vh;
  }
}

@media only screen and (width >= 700px) {
  h1 {
    font-size: 650%;
  }

  ul {
    width: max-content;
  }

  li {
    padding-left: 30px;
  }

  li:first-of-type {
    padding-left: 0;
  }

  .avatar {
    width: 16vh;
    height: 16vh;
  }
}

@keyframes background-pan {
  from {
    background-position: 0%;
  }

  to {
    background-position: -200%;
  }
}
/*# sourceMappingURL=index.0bdd5287.css.map */
